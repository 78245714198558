// JobDetailsModal.js
import React from 'react';
import { useState, useEffect } from 'react';
import { Modal, Button, Table, Row, Col } from 'react-bootstrap';
import { imgPath } from '../../../admin/common/function';
import { updateApplyJobsPostStatusAction, getPostJobsAction } from '../../../action/Admin.action';
import Swal from 'sweetalert2';
const PostApplyDetailsModel = ({ isOpen, toggle, job }) => {
    console.log('object---', job)
    const [jobData, setJobData] = useState([]);

    const getData = async () => {
        if (!job) return; // Check if job ID exists before fetching data
        let resp = await getPostJobsAction({ postId: job });
        if (resp.code === 200) {
            setJobData(resp.data);
        }
    };
    useEffect(() => {
        getData();
    }, [job]);
    const changeStatus = async (id, status, text) => {
        Swal.fire({
            title: `Confirm to ${text}`,
            text: "Are you sure to do this?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal-popup',
                title: 'custom-swal-title',
                confirmButton: 'custom-swal-confirm',
                cancelButton: 'custom-swal-cancel'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                const resp = await updateApplyJobsPostStatusAction({
                    _id: id,
                    action: status,
                });
                if (resp.code === 200) {
                    Swal.fire({
                        title: `${text}!`,
                        text: `The application has been ${text.toLowerCase()}.`,
                        icon: "success",
                        customClass: {
                            popup: 'custom-swal-popup'
                        }
                    });
                    // Update the status of the applicant
                    // const updatedApplicants = jobData.applyJobsPostData.map(applicant => {
                    //     if (applicant._id === id) {
                    //         // Set the shortlisted status
                    //         return { ...applicant, shortlisted: true };
                    //     }
                    //     return applicant;
                    // });

                    // setJobData({
                    //     ...jobData,
                    //     applyJobsPostData: updatedApplicants,
                    // });
                    getData();
                } else {
                    Swal.fire({
                        title: "Error",
                        text: "An error occurred. Please try again.",
                        icon: "error",
                        customClass: {
                            popup: 'custom-swal-popup'
                        }
                    });
                }
            }
        });
    };
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };
    return (
        <Modal show={isOpen} onHide={toggle} size="lg">
            <Modal.Header className='useModelHeader' closeButton>
                <Modal.Title id="profile-modal-title" className='profile-modal liteModal-content'>Job Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className='liteModal-content'>
                {jobData ? (
                    <div>
                        <h5 className='text-white'>Job Information</h5>
                        <Row>
                            <Col><p className='text-white'><strong>Job Name:</strong> {jobData.jobName}</p></Col>
                            <Col><p className='text-white'><strong>Company:</strong> {jobData.companyName}</p></Col>
                        </Row>
                        <Row>
                            <Col> <p className='text-white'><strong>Location:</strong> {jobData.location}</p></Col>
                            <Col> <p className='text-white'><strong>Type:</strong> {jobData.jobType}</p></Col>
                        </Row>
                        <p className='text-white'><strong>Description:</strong> {jobData.desc}</p>
                        {/* <p className='text-white'><strong>Status:</strong> {job.status === 1 ? "Active" : "Inactive"}</p> */}
                        <hr />
                        <h5 className="mt-4 text-white">Applicants</h5>
                        {jobData.applyJobsPostData && jobData.applyJobsPostData.length > 0 ? (
                            <table className='custom-responsive-table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        {/* <th>Position</th> */}
                                        <th>Description</th>
                                        <th>Resume</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jobData.applyJobsPostData.map((applicant) => (
                                        <tr key={applicant._id}>
                                            <td>{applicant.userData?.businessContactName || "N/A"}</td>
                                            <td>{applicant.userData?.email || "N/A"}</td>
                                            <td>{applicant.userData?.phone || "N/A"}</td>
                                            <td>{truncateText(`${applicant.desc}`, 17)}</td>
                                            <td>  <a
                                                href={`${imgPath(applicant.resume)}`}
                                                download
                                                target='blank'
                                                title='Download'
                                            >
                                                <i className="fas fa-download"></i>
                                            </a></td>
                                            <td>
                                                <button
                                                    className="btn"
                                                    style={{
                                                        backgroundColor: '#28a745', // Green color for Accept
                                                        color: '#FFFFFF',
                                                        fontSize: '11px',
                                                        marginRight: '5px',
                                                        padding: applicant.shortlisted ? '5px 7px' : '5px 15px', // Conditionally set padding
                                                        borderRadius: '5px',
                                                        border: 'none'
                                                    }}
                                                    onClick={() => changeStatus(applicant._id, 1, applicant.shortlisted ? "Shortlisted" : "Accepted")}
                                                    disabled={applicant.shortlisted} // Disable button if already shortlisted
                                                >
                                                    {applicant.shortlisted ? "Shortlisted" : "Accept"}
                                                </button>
                                                {!applicant.shortlisted && (
                                                    <button
                                                        className="btn"
                                                        style={{
                                                            backgroundColor: '#dc3545',
                                                            color: '#FFFFFF',
                                                            fontSize: '12px',
                                                            padding: '5px 15px',
                                                            borderRadius: '5px',
                                                            border: 'none',
                                                        }}
                                                        onClick={() => changeStatus(applicant._id, 0, "Rejected")}
                                                    >
                                                        Reject
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No applicants for this job.</p>
                        )}
                    </div>
                ) : (
                    <p>No job details available.</p>
                )}
            </Modal.Body>
            <Modal.Footer className='appledJobDetailsFooter'>
                <Button variant="secondary" onClick={toggle}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PostApplyDetailsModel;
