import React from 'react';
import Logo from "../../../assets/images/frontPageImg/logo.png";
import smallCircleButton from "../../../assets/images/frontPageImg/smallCircleButton.png";
import { Link } from 'react-router-dom';
import { Nav, } from 'react-bootstrap';
import { Form } from 'reactstrap';


function HomeFooter() {
    return (
        <div class="py-5" style={{ backgroundColor: "#0A0B27" }}>
            <div class="container">
                <div class="row">
                    <div class="col-md-4 mb-4">
                        <div class="footer-logo">
                            <Link to={`${process.env.PUBLIC_URL}/`}>
                                <img src={Logo} alt="Logo" />
                            </Link>
                        </div>

                        <div className="social-icons mt-3">
                            <a href="https://www.instagram.com/filmcrewindia_com/profilecard/?igsh=OTV1NTgxNXc2NnB0" className="icon instagram" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                                <i className="fa fa-instagram"></i>
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=61567197689181&mibextid=LQQJ4d" className="icon facebook" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                                <i className="fa fa-facebook-f"></i>
                            </a>
                            <a href="https://www.linkedin.com/in/filmcrew-india-315a10338/" className="icon linkedin" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                                <i className="fa fa-linkedin"></i>
                            </a>
                            <a href="https://x.com/filmcrewindia" className="icon twitter" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                                <i className="fa fa-x-twitter"></i>
                            </a>
                        </div>
                        <div class="newsletter mt-4">
                            <h5 class="text-white">Join Newsletter</h5>
                            <Form className='userForm'>
                                <div class="input-group">
                                    <input type="email" class="form-control custom-input" placeholder="Enter your email" required />
                                    <button class="btn" type="submit">
                                        <img src={smallCircleButton} alt="Submit" />
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div class="col-md-3 mb-4">
                        <h5 class="text-white">Sitemap</h5>
                        <ul className="list-unstyled text-white">
                            <li><a href="#About" className="text-white">About Us</a></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/category-list`} className="text-white">Categories</Link></li>
                            {/* <li><a href="#Pricing" className="text-white">Pricing</a></li> */}
                            <li><a href="#Blogs" className="text-white">Blogs</a></li>
                            <li><a href="#Contact" className="text-white">Contact Us</a></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/termsAndConditions`} className="text-white">Terms & Conditions</Link></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/privacyPolicy`} className="text-white">Privacy Policy</Link></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/disclaimerOfWarranties`} className="text-white">Disclaimer Of Warranties</Link></li>
                        </ul>
                    </div>
                    <div class="col-md-4 mb-4" >
                        <h5 class="text-white">Contact</h5>
                        {/* <p class="text-white"><i class="fas fa-map-marker-alt"></i> INDIA</p> */}
                        <p class="text-white"><i class="fas fa-building"></i> PICTURE COMPANY</p>
                        {/* <p class="text-white"><i class="fas fa-map-marker-alt"></i>E-309, 3rd floor, Crystal Plaza Premises Co.OP.Soc. Ltd,<br />New Link Road, Andheri (W),<br />Mumbai, Maharashtra,India</p> */}
                        <p><a href="mailto:info@lesueurinc.com" class="text-white"><i class="fas fa-envelope"></i>Info@pictureco.in</a></p>
                        <p class="text-white"><i class="fas fa-map-marker-alt"></i>  Office #1/B,
                            A-Wing,
                            1st floor,
                            Vimal Apartments,
                            Besides Mahindra Cars Showroom,
                            Juhu Lane,
                            Off SV road,
                            Andheri (W).
                            Mumbai - 400061</p>
                        {/* <p class="text-white"> GSTIN/UIN   : 27AGJPC6393H2Z5</p>
                        <p class="text-white"> PAN/IT No   : AGJPC6393H</p> */}
                        {/* <p class="text-white"> State Code : 27</p> */}


                    </div>
                    {/* <div class="col-md-2">
                        <h5 class="text-white">Sales Contact</h5>
                        <p><a href="mailto:info@filmcrewpro.com" class="text-white"><i class="fas fa-envelope"></i> info@filmcrewpro.com</a></p>
                        <p class="text-white"><i class="fas fa-phone-alt"></i> +91 740 047 6456</p>
                        <h5 class="text-white">General Contact</h5>
                        <p><a href="mailto:info@lesueurinc.com" class="text-white"><i class="fas fa-envelope"></i> info@lesueurinc.com</a></p>
                    </div> */}
                </div>
            </div>
        </div>

    );
}

export default HomeFooter;
