import React, { Fragment, useEffect } from "react";
import HomeHeader from "../Header/HomeHeader";
import HomeFooter from "../Footer/HomeFooter";
import SubFooter from "../Footer/SubFooter";
import HeaderloginLite from "../Header/HeaderloginLite";

const DisclaimerOfWarranties = ({ selected }) => {
    const status = JSON.parse(localStorage.getItem("status"));
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            {status === 1 ? (
                <HeaderloginLite />
            ) : (
                <HomeHeader />
            )}
            <section className="terms-and-conditions-section">
                <div className="terms-container">
                    <h2 className="text-center" style={{ color: '#FFFFFF', paddingBottom: '15px' }}>Disclaimer of Warranties</h2>
                    <p className="intro-text">
                        1. Your use of the Service is at your own risk and the Service is provided on an "as is" and "as available" basis without warranties of any kind, express or implied.
                    </p>
                    <p className="intro-text">
                        2. To the maximum extent permitted by law, Film Crew  disclaims all warranties, including implied warranties of satisfactory quality, merchantability, fitness for a particular purpose, and non-infringement.
                    </p>
                    <p className="intro-text">
                        3. Film Crew Inc. makes no warranty as to the accuracy, completeness, or reliability of materials, information, or data available through the Service.
                    </p>
                    <p className="intro-text">
                        4. You acknowledge that any material downloaded or obtained through the Service is done at your own risk, and you are solely responsible for any damage to your device or loss of data resulting from such material.
                    </p>

                </div>
            </section>
            <HomeFooter />
            <SubFooter />
        </Fragment >
    );
};

export default DisclaimerOfWarranties;
