import React, { Fragment, useState } from "react";
import { Container, Row } from "reactstrap";
import Header from "../Header/HeaderloginLite";
import "../../../assets/css/style.css";
import { logInWithEmailOrMobileAction } from "../../../action/Admin.action";
import statusBar1 from "../../../assets/images/frontPageImg/statusBar1.png";

import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";

import { Form, } from "react-bootstrap";

const LoginWithOtp = () => {
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [inputError, setInputError] = useState("");
  const navigate = useNavigate();

  const validateInput = (value) => {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    const phonePattern = /^[0-9]{10}$/;

    if (emailPattern.test(value)) {
      return "email";
    } else if (phonePattern.test(value)) {
      return "phone";
    }
    return null;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false || !validateInput(formData.inputValue)) {
      event.stopPropagation();
      setValidated(true);
      setInputError("Please provide a valid email or phone number.");
    } else {
      event.stopPropagation();
      setDisabled(true);
      const inputType = validateInput(formData.inputValue);

      try {


        let resp = await logInWithEmailOrMobileAction({ [inputType]: formData.inputValue });

        if (resp.code === 200) {
          toast.success(resp.msg);

          navigate(`${process.env.PUBLIC_URL}/otpCheck`, { state: { [inputType]: formData.inputValue } });
        } else {
          toast.error(resp.msg);
          setDisabled(false);
        }
      } catch (error) {
        toast.error('An error occurred. Please try again.');
        setDisabled(false);
      }
    }
  };

  const handleChange = (name, event) => {
    setFormData({
      ...formData,
      [name]: event.target.value
    });
    setInputError("");
  };

  return (
    <Fragment>
      <Container fluid={true} className="p-0">
        <Header />
        <Container>
          <Row>
            <div className="pt-5 pb-4">
              <img
                src={statusBar1}
                alt=""
                style={{ maxWidth: "100%" }}
              />
            </div>
          </Row>
          <div className="pt-3">
            <h2 className="text-white">Welcome</h2>
            <p className="text-white">
              Please enter your email or phone number to continue.
            </p>
          </div>
          <div className="d-flex  align-items-center">
            <Form className="userForm mt-2" noValidate validated={validated} onSubmit={handleSubmit} style={{ width: "300px", marginBottom: '20px' }}>
              <Form.Group>
                <Form.Label className="text-white mb-2">
                  Email / Phone<span className="star">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email or phone number"
                  value={formData.inputValue || ''}
                  onChange={(e) => { handleChange('inputValue', e) }}
                  required
                  isInvalid={!!inputError}
                />
                <Form.Control.Feedback type="invalid">
                  {inputError || "Please provide a valid Email or Phone number."}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="mt-4">
                <button
                  type="submit"
                  style={{ backgroundColor: "#AA1163", width: 200 }}
                  className="btn btn-square text-white"
                  disabled={isDisabled}
                >
                  Continue
                </button>
              </div>
            </Form>
          </div>
        </Container>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default LoginWithOtp;
