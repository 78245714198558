import { Country, State, City } from 'country-state-city';
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import React from 'react';
// import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
// import XLSX from 'xlsx';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'


export const objToArray = (obj) => {

  let newArray = [];
  for (let i in obj) {
    newArray[i] = obj[i];
  }
  return newArray;
}

export const checkFile = (post) => {

  let errorObj = {};
  let error = "";
  let file = post.event.target.files[0];
  if (!file && file === undefined) {
    error = "Unknown file. Please select another file.";
  }
  var fileName = (file && file.name ? file.name : '');
  let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
  let byte = 0;
  post.sizeType = post.sizeType ? post.sizeType : 'KB';

  if (post.sizeType === 'MB') {
    byte = post.size * 1048576;
  } else if (post.sizeType === 'KB') {
    byte = post.size * 1024;
  }


  if (byte && file.size <= byte && post.exts.includes(extensions)) {

    return true;

  } else if (byte && file.size > byte) {

    error = "File size not greater then " + post.size + post.sizeType + ".";

  } else {
    error = "File extensions doesn't match. Only " + post.exts.join(',') + " file allow.";
  }

  let errorF = post.name;

  errorF += post.lanId ? post.lanId : "";
  errorF += post.index > -1 ? post.index : "";
  errorObj[errorF] = error;

  return errorObj;

}

export const getFieldValue = async (id, field, list) => {
  let item = list.find(a => a.id == id);
  return (item && item[field]) ? item[field] : "";
}

export const emailCheck = (email) => {
  // return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
  return /[a-zA-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,8}(.[a-z{2,8}])?/i.test(email)
}

export const emailValidation = (email) => {
  var emailValidation = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  if (email.match(emailValidation)) {
    return true;
  }
  else {
    return false;
  }
}

export const checkMobileNumber = (evt1) => {
  // console.log(evt1);
  var phoneno = /^\d{10}$/;
  if (evt1.match(phoneno)) {
    return true;
  }
  else {
    return false;
  }
}

export const checkNumber = (evt2) => {
  var chkNo = /^\d{1,15}$/;
  if (evt2.match(chkNo)) {
    return true;
  }
  else {
    return false;
  }
}

export const checkName = (evt3) => {
  var chkName = /^[a-zA-Z0-9_ ]{1,30}$/;
  if (evt3.match(chkName)) {
    return true;
  }
  else {
    return false;
  }
}

export const getProject = () => {

  let project = {};

  if (localStorage.getItem('project') && localStorage.getItem('project') != '') {
    project = JSON.parse(localStorage.getItem('project'));
  }

  return project;
}

export const imgPath = (image) => {

  //return  process.env.REACT_APP_API_URL+'/'+image;
  //return  'http://3.109.97.64:3005/'+image;

  return 'https://zena-media.s3.ap-south-1.amazonaws.com/productionDev/' + image;

}


export const GetAllMobileCode = ({ valueType = '' }) => {
  const countriesList = Country.getAllCountries();
  return <>
    <option value="">{valueType === 'Code' ? 'Code' : 'Country'}</option>
    {
      countriesList.length && countriesList.map((item, index) => {
        if (valueType === 'Code') {
          if (item.phonecode && item.phonecode !== '') {
            // let code = item.phonecode.replace('+', '');
            return <option value={item.phonecode} key={item.name}>{item.flag}</option>
          } else {
            return '';
          }
        } else {
          return <option value={item.phonecode} key={item.name + item.isoCode} name={item.name}>{item.phonecode}{" "}{item.flag}{" "}{item.name}</option>
        }
      })
    }
  </>;
}

export const GetAllCountries = ({ valueType = '' }) => {
  const countriesList = Country.getAllCountries();
  return <>
    <option value="">{valueType === 'Code' ? 'Code' : 'Country'}</option>
    {
      countriesList.length && countriesList.map((item, index) => {
        if (valueType === 'Code') {
          if (item.phonecode && item.phonecode !== '') {
            let code = item.phonecode.replace('+', '');
            return <option value={code} key={item.name + code}>{item.name} (+{code})</option>
          } else {
            return '';
          }
        } else {
          return <option value={item.isoCode} key={item.name + item.isoCode} name={item.name}>{item.name}</option>
        }
      })
    }
  </>;
}

export const GetStatesOfCountry = ({ countryCode }) => {

  const statesList = State.getStatesOfCountry(countryCode);
  return <>
    <option value="">State</option>
    {
      statesList.length && statesList.map((item, index) => {
        return <option value={item.isoCode} key={item.name + item.isoCode} name={item.name}>{item.name}</option>
      })
    }
  </>
}

export const GetCitiesOfState = ({ countryCode, stateCode }) => {

  const citesList = City.getCitiesOfState(countryCode, stateCode);

  return <>
    <option value="">City</option>
    {
      citesList.length && citesList.map((item, index) => {
        return <option value={item.name} key={item.name + item.countryCode}>{item.name}</option>
      })
    }
  </>
}

// export const HalfGaugeChartWithNeedle = ({ value, minValue, maxValue }) => {
//     const normalizedValue = (value - minValue) / (maxValue - minValue);
//     const angle = normalizedValue * 180;

//     return (
//       <svg width="200" height="120">
//         {/* Gauge background */}
//         <path d="M10,60 A50,50 0 1,0 190,60" fill="#f0f0f0" />

//         {/* Gauge value */}
//         <path
//           d={`M10,60 A50,50 0 ${angle > 180 ? 1 : 0},1 ${10 + 180 * normalizedValue},60 L100,110 L10,110 Z`}
//           fill="#007acc"
//         />

//         {/* Needle */}
//         <line x1="100" y1="10" x2="100" y2="110" stroke="black" strokeWidth="2" transform={`rotate(${angle} 100 60)`} />
//       </svg>
//     );
//   }; 

//first install ====> npm install jspdf jspdf-autotable --save
// const generatePDF=()=> {
//     const doc = new jsPDF();

//     doc.setProperties({
//       title: 'Crew_List',
//     });

//     const headers = [['S.no.', 'Name', 'Department','Designation','Mobile','Email']];

//     const rows = dataList.map((item,key) => [key+1, item.name, item.department, item.designation, item.mobile, item.email]);

//     const tableX = 10;
//     const tableY = 20;

//     const columnWidths = [20, 60, 60];

//     doc.autoTable({
//       head: headers,
//       body: rows,
//       startY: tableY,
//       startX: tableX,
//       columnWidth: columnWidths,
//     });

//     const timestamp = new Date().toISOString();
//     const pdfName = `CrewList_${timestamp}.pdf`;
//     doc.save(pdfName);
//   }




export const generateExcel = (dataList, name) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`${name}`);

  const headers = Object.keys(dataList[0]);

  const firstRowStyle = {
    alignment: { horizontal: 'center' },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '20B2AA' },
    },
    font: {
      size: 12,
    },
  };

  headers.forEach((header, index) => {
    const cell = worksheet.getCell(1, index + 1);
    cell.value = header;
    cell.style = firstRowStyle;
  });

  for (let i = 0; i < dataList.length; i++) {
    const dataRow = dataList[i];
    headers.forEach((header, index) => {
      worksheet.getCell(i + 2, index + 1).value = dataRow[header];
    });
  }

  headers.forEach((header, index) => {
    const column = worksheet.getColumn(index + 1);
    let maxLength = header.length;
    dataList.forEach((dataRow) => {
      const cellValue = dataRow[header];
      if (cellValue) {
        maxLength = Math.max(maxLength, String(cellValue).length);
      }
    });
    column.width = Math.max(maxLength + 2, header.length + 2);
  });

  const excelBlob = workbook.xlsx.writeBuffer().then((buffer) => {
    return new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  });

  const timestamp = new Date().toISOString();
  const excelName = `${name}${timestamp}.xlsx`;

  return excelBlob.then((blob) => {
    saveAs(blob, excelName);
  });
};

export const generateExcel1 = (dataList, name) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`${name}`);

  const headers = Object.keys(dataList[0]);

  const firstRowStyle = {
    alignment: { horizontal: 'center' },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '20B2AA' },
    },
    font: {
      size: 12,
    },
  };

  headers.forEach((header, index) => {
    const cell = worksheet.getCell(1, index + 1);
    cell.value = header;
    cell.style = firstRowStyle;
  });

  for (let i = 0; i < dataList.length; i++) {
    const dataRow = dataList[i];
    headers.forEach((header, index) => {
      worksheet.getCell(i + 2, index + 1).value = dataRow[header];
    });
  }

  headers.forEach((header, index) => {
    const column = worksheet.getColumn(index + 1);
    let maxLength = header.length;
    dataList.forEach((dataRow) => {
      const cellValue = dataRow[header];
      if (cellValue) {
        maxLength = Math.max(maxLength, String(cellValue).length);
      }
    });
    column.width = Math.max(maxLength + 2, header.length + 2);
  });

  const excelBlob = workbook.xlsx.writeBuffer().then((buffer) => {
    return new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  });

  const timestamp = new Date().toISOString();
  const excelName = `${name}${timestamp}.xlsx`;

  return excelBlob.then((blob) => {
    saveAs(blob, excelName);
  });
};

//first install ====> npm install jspdf jspdf-autotable --save
export const generatePDF = (dataList, name) => {
  const doc = new jsPDF();

  doc.setProperties({
    title: 'Crew_List',
  });

  const headers = [['S.no.', 'Name', 'Department', 'Designation', 'Mobile', 'Email']];

  const rows = dataList.map((item, key) => [key + 1, item.name, item.departmentName, item.designationtName, item.mobile, item.email]);

  const tableX = 10;
  const tableY = 20;

  const columnWidths = [20, 60, 60];

  doc.autoTable({
    head: headers,
    body: rows,
    startY: tableY,
    startX: tableX,
    columnWidth: columnWidths,
  });

  const timestamp = new Date().toISOString();
  const pdfName = `CrewList_${timestamp}.pdf`;
  doc.save(pdfName);
}

export const generatePDF1 = (dataList, name, headers) => {
  const doc = new jsPDF();

  doc.setProperties({
    title: `${name}`,
  });
  if (name === "Vendor") {
    var rows = dataList.map((item, key) => [key + 1, item.name, item.departmentName, item.designationtName, item.mobile, item.email]);
  }
  else if (name === "Master Breakdown") {
    var rows = dataList.map((item, key) => [key + 1, item.name, item.departmentName, item.designationtName, item.mobile, item.email]);
  }


  const tableX = 10;
  const tableY = 20;

  const columnWidths = [20, 60, 60];

  doc.autoTable({
    head: headers,
    body: rows,
    startY: tableY,
    startX: tableX,
    columnWidth: columnWidths,
  });

  const timestamp = new Date().toISOString();
  const pdfName = `${name}+${timestamp}.pdf`;
  doc.save(pdfName);
}

function ShareComponent(data1, url) {
  const handleShare = () => {
    // Create a new Excel workbook and add a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data1);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Data1");

    // Create a data blob for the Excel file
    const blob = XLSX.write(wb, { bookType: 'xlsx', type: 'blob' });

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);

    // Create a download link and trigger a click event to download the file
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data1.xlsx';
    a.click();

    // Clean up by revoking the URL
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <button onClick={handleShare}>Share Excel</button>
    </div>
  );
}

export default ShareComponent;