import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { getMemberOnDepartment, getCategoryVendorDataAction, addEditEnquiryAction } from "../../../action/Admin.action";
import { Container, Row, Col, Card, Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router";
import HomeHeader from "../Header/HomeHeader";
import Headerlogin from "../Header/HeaderloginLite";

import searchIcon from "../../../assets/images/frontPageImg/interface.png";
import HomeFooter from "../Footer/HomeFooter";
import LoginWithOtp from "./LoginWithOtp";
import SubFooter from "../Footer/SubFooter";

const CategoryProfileListing = ({ selected }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const [isDisabled, setDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({ name: '', subCategoryId: '' });
    const [suggestions, setSuggestions] = useState([]);
    const [matchSubCategories, setMatchSubCategories] = useState([]);
    const [subCategoryId, setSubCategoryId] = useState('');
    const status = JSON.parse(localStorage.getItem("status"));
    const [showModal, setShowModal] = useState(false);
    const [showApplyModal, setShowApplyModal] = useState(false);
    const handleOpenApplyModal = () => setShowApplyModal(true);
    const handleCloseApplyModal = () => setShowApplyModal(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [formEnquryData, setFormEnqueryData] = useState({
        description: '',
        memberId: ''
    });

    var categoryId = location.state?.id?._id || '';
    const categoryName = location.state?.id?.name || '';
    const businessCountry = location.state?.businessCountry || '';
    const businessState = location.state?.businessState || '';
    const businessCity = location.state?.businessCity || '';

    const fetchCategoryData = async () => {
        setLoading(true);
        const response = await getMemberOnDepartment({ categoryId, subCategoryId, businessCountry, businessState, businessCity });

        if (response.code === 200) {
            setCategoryData(response.data);
        }
        setLoading(false);
    };

    const getData = async () => {

        if (categoryId) {
            const resp = await getCategoryVendorDataAction();
            if (resp && resp.code === 200) {
                const matchedCategory = resp.data.find(item => item._id === categoryId);
                if (matchedCategory) {
                    const subCategories = matchedCategory.subCategory || [];
                    setMatchSubCategories(subCategories)
                } else {
                    console.warn("No matching category found for categoryId:", categoryId);
                }
            } else {
                console.error("Error fetching suggestions:", resp);
            }
        } else {

        }
    };
    const handleSendEnqueryNow = (Id) => {
        setFormEnqueryData({ ...formEnquryData, memberId: Id });
        setShowModal(true);
    };
    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            event.preventDefault();
            event.stopPropagation();
            setValidated(false);
            setDisabled(true);

            const postData = {
                description: formEnquryData.description,
                memberId: formEnquryData.memberId
            };

            try {
                const response = await addEditEnquiryAction(postData);

                if (response.code === 200) {
                    setSuccessMessage("Your enquiry has been sent successfully!");
                    setErrorMessage('');

                    setFormEnqueryData({
                        description: '',
                        memberId: ''
                    });
                    setTimeout(() => {
                        setSuccessMessage('');
                        setShowModal(false);
                    }, 2000);
                } else {
                    setErrorMessage(response.msg);
                    setSuccessMessage('');
                }
            } catch (error) {
                setErrorMessage("Failed to send your enquiry. Please check and try again.");
                setSuccessMessage('');
                console.error("Error submitting send your enquiry:", error);
            } finally {
                setDisabled(false);
            }
        }
    };
    const handleSendEnqueryInputChange = (e) => {
        const { name, value } = e.target;
        setFormEnqueryData({ ...formEnquryData, [name]: value });
    };
    const handleCloseModal = () => {
        setShowModal(false);
        setSuccessMessage('');
        setErrorMessage('');
        setFormEnqueryData({
            description: '',
            memberId: ''
        });
    };
    useEffect(() => {

        getData();
        fetchCategoryData();

    }, [categoryId, subCategoryId, categoryName, businessCountry, businessState, businessCity]);


    const fetchSuggestions = async (name) => {
        if (name.length >= 1) {
            const resp = await getMemberOnDepartment({ name });
            if (resp && resp.code === 200) {
                setSuggestions(resp.data);
            } else {
                console.error("Error fetching suggestions:", resp);
            }
        } else {
            setSuggestions([]);
        }
    };


    const handleInputChange = async (e) => {
        const name = e.target.value;
        setFormData({ ...formData, name });

        await fetchSuggestions(name);
    };

    const handleSuggestionClick = (category) => {
        setFormData({ ...formData, name: category.businessContactName });
        setSuggestions([]);
    };


    const handleSearch = async (e) => {
        e.preventDefault();
        const { name } = formData;
        const subCategoryId = formData.subCategoryId;
        const age = formData.age;
        if (name || subCategoryId || (categoryName === 'Artist' && age)) {
            try {

                const response = await getMemberOnDepartment({ name, subCategoryId, categoryId, businessCountry, businessState, businessCity, age });

                if (response && response.code === 200) {

                    const matchedData = response.data;
                    setCategoryData(matchedData);
                } else {
                    console.error("Error fetching search results:", response);
                }
            } catch (error) {
                console.error("Error occurred during search:", error);
            }
        } else {
            console.warn("Please provide either a search keyword or select a subcategory.");
        }
    };

    const handleCardClick = (id) => {
        navigate(`${process.env.PUBLIC_URL}/categoryProfileDetails`, { state: { id, categoryName } });
    };
    // const status = JSON.parse(localStorage.getItem("status"));
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <Fragment>
            {status === 1 ? (
                <Headerlogin />
            ) : (
                <HomeHeader />
            )}
            <section className="search-baree-section">
                <Container className="search-bar" >
                    <Row style={{ marginTop: status === 1 ? '50px' : '150px', paddingBottom: '20px' }}>
                        <Col md={8} >
                            <Form onSubmit={handleSearch} style={{ paddingTop: '25px' }}>
                                <Row className="g-2">
                                    <Col xs={12} md={4}>
                                        <Form.Group>
                                            <Form.Select
                                                name="subCategoryId"
                                                value={formData.subCategoryId || ''}
                                                onChange={(e) => setFormData({ ...formData, subCategoryId: e.target.value })}
                                            >
                                                <option value="">Select Subcategory</option>
                                                {matchSubCategories.map((sub, index) => (
                                                    <option value={sub._id} key={index}>{sub.name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Sub Category.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    {categoryName === "Artist" && (
                                        <Col xs={12} md={4}>

                                            <Form.Group>
                                                {/* <div className="mb-2"> */}
                                                {/* <Form.Label className="text-white mb-2">Age<span className="star"> *</span></Form.Label> */}
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    value={formData.age ? formData.age : ""}
                                                    onChange={e => setFormData({ ...formData, age: e.target.value })}
                                                // required
                                                >
                                                    <option value="">Select Age</option>
                                                    <option value="1-10">1-10</option>
                                                    <option value="11-18">11-18</option>
                                                    <option value="19-25">19-25</option>
                                                    <option value="26-35">26-35</option>
                                                    <option value="36-50">36-50</option>
                                                    <option value="50 & above">50 & above</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid Age.
                                                </Form.Control.Feedback>
                                                {/* </div> */}
                                            </Form.Group>
                                        </Col>
                                    )}
                                    <Col xs={12} md={4}>
                                        <div className="d-flex search-baree" >
                                            <Form.Control
                                                style={{ display: 'none' }}
                                                type="text"
                                                name="name"
                                                placeholder="🔍 Search for Keyword"
                                                className="custom-inputee custom-placeholder"
                                                onChange={handleInputChange}
                                                value={formData.businessContactName}
                                                autocomplete="off"
                                            />
                                            <Button className="search-button" type="submit">
                                                <img src={searchIcon} alt="Search" />
                                            </Button>
                                        </div>

                                        {suggestions.length > 0 && (
                                            <ul className="suggestions-list">
                                                {suggestions.map((category) => (
                                                    <li
                                                        key={category._id}
                                                        onClick={() => handleSuggestionClick(category)}
                                                        className="suggestion-item"
                                                    >
                                                        <span className="suggestion-icon">🔍</span>
                                                        <div className="suggestion-details">
                                                            <span className="suggestion-name">{category.name}</span>
                                                            <span className="suggestion-category">Category</span>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col md={4} className="text-end" style={{ paddingTop: '25px' }} >
                            <button className="ms-2 custom-button" style={{ width: '105px', padding: '5px', borderRadius: '8px' }} onClick={() => navigate(-1)}>Back</button>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <div className="card-grid mt-5">
                        {categoryData && categoryData.length > 0 ? (
                            categoryData.map((item, index) => (

                                <Card key={index} className="card-custom hand" onClick={() => handleCardClick(item._id, categoryName)}>
                                    <div className="rating-card">
                                        <div className="rating-badge">
                                        </div>
                                    </div>
                                    <Card.Body className="d-flex flex-column" style={{ padding: '1px 10px 15px 10px' }}>
                                        <div className="card-content flex-grow-1">
                                            <Card.Title>{item.businessContactName}</Card.Title>
                                            <Card.Subtitle className="mb-2">{categoryName}</Card.Subtitle>
                                            <Card.Text><i className="fas fa-map-marker-alt"></i> {item.businessAddress}</Card.Text>
                                        </div>
                                        <div className="button-group mt-auto">
                                            {/* <Button variant="danger" className="me-2">Send Enquiry</Button>
                                            <Button variant="success">Call Now</Button> */}
                                        </div>
                                        <div className="button-group mt-2">
                                            <button
                                                className="btn"
                                                // onClick={() => handleSendEnqueryNow(item.jobName, item._id)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (status === 1) {
                                                        handleSendEnqueryNow(item._id);
                                                        // navigate(`${process.env.PUBLIC_URL}/user/dashboard`);
                                                    } else {
                                                        navigate(`${process.env.PUBLIC_URL}/loginWithOtp`);
                                                        // handleOpenApplyModal();
                                                    }
                                                }}
                                                style={{ backgroundColor: '#AA1163', color: '#FFFFFF', fontSize: '12px' }}
                                            >
                                                Send Enquiry
                                            </button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            ))
                        ) : (
                            <div className="no-data">
                                <p style={{ fontSize: '19px' }}>Members not available</p>
                            </div>
                        )}
                    </div>
                </Container>
                {
                    status === 1 ? (
                        <></>
                    ) : (
                        <>
                            <HomeFooter />
                            <SubFooter />
                        </>
                    )
                }
            </section>
            <Modal show={showModal} backdrop="static" onHide={handleCloseModal}>
                <Modal.Header className='useModelHeader' closeButton>
                    <Modal.Title>Send Enquiry </Modal.Title>
                </Modal.Header>
                <Modal.Body className='liteModal-content'>
                    <Form className="userForm" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group controlId="formDesc" className="mt-3">
                            <Form.Label className="text-white mb-2">Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="description"
                                value={formEnquryData.description}
                                onChange={handleSendEnqueryInputChange}
                                placeholder="Enter a description"
                                autocomplete="off"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Description.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}

                        {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}


                        <div className="mt-4">
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>{' '}
                            <Button variant="primary" type="submit" style={{ marginLeft: '5px' }} >
                                Save
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
                {/* <Modal.Footer className='useModelHeader'> */}

                {/* </Modal.Footer> */}
            </Modal>

            <Modal show={showApplyModal} onHide={handleCloseApplyModal} size="lg" centered>
                <Modal.Header className='useModelHeader' closeButton>
                    <Modal.Title className='text-white'>Login / Sign Up</Modal.Title>
                </Modal.Header>
                <Modal.Body className='loginModelBg'>
                    <LoginWithOtp />
                </Modal.Body>
            </Modal>
        </Fragment >
    );
};

export default CategoryProfileListing;
