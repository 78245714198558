// JobDetailsModal.js
import React from 'react';
import { Modal, Button, Table, Row, Col } from 'react-bootstrap';
import { imgPath } from '../../../admin/common/function';

const SenderEnquiryDetailModel = ({ isOpen, toggle, enquiry }) => {
    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options); // 'en-GB' for dd/mm/yyyy format
    };
    if (!enquiry) return null;

    return (
        <Modal show={isOpen} onHide={toggle} size="lg" centered>
            <Modal.Header className='useModelHeader' closeButton>
                <Modal.Title className='profile-modal liteModal-content'>Enquiry Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className='liteModal-content'>
                {/* Enquiry Basic Information */}
                <div>
                    <p className='text-white'> <strong>Description:</strong>  {enquiry.description}</p>
                </div>
                <Row>
                    <Col><p className='text-white'><strong>Date:</strong> {formatDate(enquiry.createdAt)}</p></Col>
                    {/* <Col><p className='text-white'> <strong>Read Status:</strong>  {enquiry.read ? 'Read' : 'Unread'}</p></Col> */}
                </Row>
                <hr />

                {/* Sender Information */}
                <h5 className='text-white'>Sender Details:</h5>
                <div className='text-white p-1'><strong>Name:</strong> {enquiry.senderDetail.name}</div>
                <div className='text-white p-1'><strong>Email:</strong> {enquiry.senderDetail.email}</div>
                <div className='text-white p-1'><strong>Phone:</strong> {enquiry.senderDetail.phone}</div>
                <div className='text-white p-1'><strong>Category:</strong> {enquiry.senderDetail.categoryName}</div>
                <div className='text-white p-1'><strong>Sub-Category:</strong> {enquiry.senderDetail.subCategoryName}</div>
            </Modal.Body>
            <Modal.Footer className='appledJobDetailsFooter'>
                <Button variant="secondary" onClick={toggle}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SenderEnquiryDetailModel;
