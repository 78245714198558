import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const TermsAndConditionPolicyModel = ({ show, onHide, content }) => {
    return (
        <Modal show={show} onHide={onHide} size='lg' centered>
            <Modal.Header className='useModelHeader' closeButton>
                <Modal.Title className='text-white'>
                    {content === 'Terms and Conditions Content'
                        ? 'Terms and Conditions'
                        : 'Privacy Policy'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='loginModelBg modalbodyTermAndCondition'>
                {content === 'Terms and Conditions Content' ? (
                    <>
                        {/* <h5>Terms and Conditions</h5> */}

                        <div className="container">
                            {/* <h2 className="text-center" style={{ color: '#FFFFFF', paddingBottom: '15px' }}>Terms and Conditions</h2> */}
                            <p className="intro-text">
                                Last Updated: October 22,2024 <br></br>
                                Welcome to FILM CREW! By using our application, you agree to the following terms and conditions. Please read them carefully.
                            </p>
                            {/* <p className="intro-text">By accessing or using the App, or by clicking to accept or agree to this ToU if presented to you in a user interface, you agree to be bound by this ToU. If you do not agree to these terms, you may not use the App.</p> */}
                            <p className="intro-text"><span className="spanText">1.Acceptance of Terms:</span><br></br> By accessing or using Film Crew, you agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree, please do not use the app.</p>
                            <p className="intro-text"> <span className="spanText">2.User Accounts:</span><br></br>. Registration: To use certain features, you may need to create an account. You agree to provide accurate and complete information and to update it as necessary.<br></br>. Account Security: You are responsible for maintaining the confidentiality of your password and account. Notify us immediately of any unauthorized use.
                            </p>
                            <p className="intro-text"> <span className="spanText">3.User Content:</span><br></br>. Ownership: You retain ownership of the content you create and share. However, by submitting content, you grant us a non-exclusive, worldwide, royalty-free license to use, display, and distribute it.<br></br>. Prohibited Content: You must not post content that is offensive, infringes copyright, or violates any laws. We reserve the right to remove such content.
                            </p>
                            <p className="intro-text"> <span className="spanText">4.Reporting Inappropriate Content:</span><br></br> If you encounter any inappropriate content, please report it through our reporting feature. We will review and take appropriate action.
                            </p>
                            <p className="intro-text"> <span className="spanText">5.Security Measures:</span><br></br> We implement various security measures, including Two-Factor Authentication (2FA), to protect your account. However, you are responsible for your own account security.
                            </p>
                            <p className="intro-text"> <span className="spanText">6.License Terms:</span><br></br> You are granted a limited, non-transferable license to use the app. You may not reproduce, distribute, or create derivative works from any part of the app.
                            </p>
                            <p className="intro-text"> <span className="spanText">7.Intellectual Property:</span><br></br> All intellectual property rights related to the app and its content are owned by Picture Company. You may not use our trademarks or copyrighted materials without permission.
                            </p>
                            <p className="intro-text"> <span className="spanText">8.Termination:</span><br></br> We reserve the right to terminate your account for violations of these Terms. You may appeal the decision by contacting our support team.
                            </p>
                            <p className="intro-text"> <span className="spanText">9.Limitation of Liability:</span><br></br> Picture Company is not liable for any damages arising from your use of the app, including indirect, incidental, or consequential damages.
                            </p>
                            <p className="intro-text"> <span className="spanText">10.Changes to Terms:</span><br></br> We may update these Terms from time to time. You will be notified of any significant changes. Continued use of the app after changes indicates your acceptance of the new terms.
                            </p>
                            <p className="intro-text"> <span className="spanText">11.Governing Law:</span><br></br> These Terms shall be governed by the laws of Maharashtra, India, without regard to its conflict of law principles.
                            </p>
                            <p className="intro-text"> <span className="spanText">12.Contact Information:</span><br></br> For any questions or concerns about these Terms, please contact us at +91 98200 86456.
                            </p>
                        </div>

                    </>
                ) : (
                    <>
                        {/* <h5>Privacy Policy</h5> */}

                        <div className="container">
                            {/* <h2 className="text-center" style={{ color: '#FFFFFF', paddingBottom: '15px' }}>Privacy Policy</h2> */}
                            <p className="intro-text">
                                By accepting this ToU or using the App or the Service, you represent that you have read and consent to our Privacy Policy in addition to this ToU. Film Crew may revise the Privacy Policy at any time, and a link to the new versions will be posted on the App. If at any point you do not agree to any portion of the Privacy Policy, you must immediately stop using the App and the Service. By using the App or the Service, you agree to the then-current versions of the ToU and Privacy Policy, which will be posted on the App.
                            </p>
                            <span className="spanText text-white">1. Location</span>
                            <p className="intro-text">
                                The Film Crew app and its associated services (the "Service") are operated by Film Crew Inc. in the United States. If you access the Service from a location outside of the United States, you do so on your own initiative and are responsible for compliance with applicable local laws.
                            </p>
                            <span className="spanText text-white">2. Submitted Content.</span>
                            <p className="intro-text"> <span className="spanText">2.1. Content of Communications:</span> Film Crew Inc. is not the source of, does not verify or endorse, and takes no responsibility for the content of communications made using the Service. By using the Service, you agree that any content you submit may be viewable by other users of the Service. The content of communications is entirely the responsibility of the person from whom such content originated. You may be exposed to content that is offensive, unlawful, harmful to minors, obscene, indecent, or otherwise objectionable. Content may be protected by intellectual property rights owned by third parties. You are responsible for ensuring you do not submit material that is (a) copyrighted, protected by trade secret, or otherwise subject to third-party proprietary rights, including privacy and publicity rights, unless you are the owner or have permission from the rightful owner; (b) a falsehood or misrepresentation; (c) offensive, unlawful, harmful to minors, obscene, defamatory, libelous, threatening, pornographic, harassing, hateful, racially or ethnically offensive, or that encourages criminal conduct, gives rise to civil liability, violates any law, or is otherwise objectionable; (d) an advertisement or solicitation of business; or (e) impersonating another person. Film Crew Inc. may, at its sole discretion, block, prevent delivery of, or remove any content to protect the Service or its users, or to enforce these terms. Film Crew Inc. may also terminate your access if you breach these terms.
                            </p>
                            <p className="intro-text"> <span className="spanText">2.2. Ownership and License:</span> Film Crew Inc. does not claim ownership of any content you submit or make available through the Service ("Submitted Content"). By providing Submitted Content, you grant Film Crew Inc. a perpetual, irrevocable, non-terminable, transferrable, worldwide, royalty-free, sublicensable, fully paid-up, non-exclusive license to use, reproduce, distribute, prepare derivative works of, display, modify, copy, and perform the Submitted Content in connection with the Service and Film Crew Inc.’s business. This includes promoting and redistributing part or all of the Service in any media format and through any media channels. You also grant each user of the Service a non-exclusive license to access your Submitted Content through the Service. You may remove any Submitted Content at any time, but Film Crew Inc. may still access such content, and the licenses granted will remain in effect despite removal.
                            </p>
                            <p className="intro-text"> <span className="spanText">2.3. Responsibility for Submitted Content:</span> You acknowledge and agree that: (a) by using the Service, you may encounter content you find offensive or indecent, and you do so at your own risk; (b) you are solely responsible for any Submitted Content you create, submit, post, or publish; and (c) Film Crew Inc. is not responsible for any Submitted Content you access through the Service, and all Submitted Content is the responsibility of its originator. Film Crew Inc. does not control the use of Submitted Content by users and may not be able to remove certain content uploaded to another user's device. Film Crew Inc. does not endorse any Submitted Content and disclaims all liability related to it.
                            </p>
                            <p className="intro-text"> <span className="spanText">2.4. Pre-Screening and Removal:</span>
                                Film Crew Inc. has the right to pre-screen any Submitted Content but has no obligation to do so. Film Crew Inc. may include Submitted Content in the Service, in whole or in part, and in modified form. Film Crew Inc. and its designees have the right to refuse or remove any Submitted Content that violates these terms or is otherwise objectionable.
                            </p>
                            <p className="intro-text"> <span className="spanText">2.5. Limitation of Liability:</span>
                                You are solely responsible for your Submitted Content. Film Crew Inc. is not liable for any errors or omissions in Submitted Content, or any loss or damage resulting from the use or distribution of such content.
                            </p>
                        </div>

                    </>
                )}
            </Modal.Body>
            <Modal.Footer className='loginModelBg'>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TermsAndConditionPolicyModel;
