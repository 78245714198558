import Verified from '../FrontPage/Componets/Pages/Verified';
import SelectUserType from '../FrontPage/Componets/Pages/SelectUserType';
// import UserRegister from '../FrontPage/Componets/Pages/UserRegister';
import CategoryPage from '../FrontPage/Componets/Pages/CategoryPage';
import UserDashboard from '../FrontPage/Componets/Pages/UserDashboard';
import MyBookings from '../FrontPage/Componets/Pages/MyBookings';
import AddEditJob from '../FrontPage/Componets/Pages/AddEditJob';


export const routes = [

    { path: `${process.env.PUBLIC_URL}/verified`, Component: <Verified /> },
    { path: `${process.env.PUBLIC_URL}/selectUserType`, Component: <SelectUserType /> },
    // { path: `${process.env.PUBLIC_URL}/userRegister`, Component: <UserRegister /> },
    { path: `${process.env.PUBLIC_URL}/user/dashboard`, Component: <UserDashboard /> },
    { path: `${process.env.PUBLIC_URL}/user/category`, Component: <CategoryPage /> },
    { path: `${process.env.PUBLIC_URL}/user/myBookings`, Component: <MyBookings /> },
    { path: `${process.env.PUBLIC_URL}/user/addJob`, Component: <AddEditJob /> },
    { path: `${process.env.PUBLIC_URL}/user/EditJob`, Component: <AddEditJob /> },

];
